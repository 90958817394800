import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { DateTime } from 'luxon'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'

import { IAuthAPI } from './IAuthAPI'
import { DefaultCredentials } from './model/DefaultCredentials'

const REFRESH_TOKEN_KEY = 'hwp.refreshToken'
const REFRESHING_TOKEN_KEY = 'hwp.refreshingToken'
const ORGANIZATION_HEADER_KEY = 'x-organization-id'

interface RequestConfig extends AxiosRequestConfig {
    tryRefresh?: boolean
}

const waitUntil = (checkFunction: () => boolean, interval = 10, maxTry = 100): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const check = (count: number) => {
            setTimeout(() => {
                if (count > maxTry) {
                    reject('Max try reached')
                } else if (checkFunction()) {
                    resolve()
                } else {
                    check(count++)
                }
            }, interval)
        }

        check(0)
    })
}

export class FullAuthAPI implements IAuthAPI {
    private accessToken: string | null = null
    private requestInterceptor?: number

    private routes = {
        login: makeURL('/account/users/login/'),
        loginExternal: makeURL('/account/users/login/external/'),
        redirectExternalLogin: makeURL('/account/users/login/redirect-external/'),
        refreshAccessToken: makeURL('/account/users/refresh-token/')
    }

    private get refreshToken(): string | null {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    }

    private set refreshToken(token: string | null) {
        if (!token) {
            localStorage.removeItem(REFRESH_TOKEN_KEY)
            return
        }
        localStorage.setItem(REFRESH_TOKEN_KEY, token)
    }

    private get isRefreshing(): boolean {
        return localStorage.getItem(REFRESHING_TOKEN_KEY) === 'true'
    }

    private set isRefreshing(isRefreshing: boolean) {
        localStorage.setItem(REFRESHING_TOKEN_KEY, `${isRefreshing}`)
    }

    public async initialize(): Promise<void> {
        this.refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)
        await this.refreshAccessToken()
    }

    private setAccessToken(token: string | null) {
        this.accessToken = token

        if (this.requestInterceptor !== undefined) {
            axios.interceptors.request.eject(this.requestInterceptor)
        }

        if (token !== null) {
            try {
                const decoded = jwtDecode<JwtPayload>(token)
                this.requestInterceptor = axios.interceptors.request.use(async (config: RequestConfig) => {
                    if (config.withCredentials === false) {
                        return config
                    }

                    // Refresh access token if necessary
                    if (!config.tryRefresh && decoded.exp && DateTime.fromSeconds(decoded.exp) <= DateTime.now()) {
                        config.tryRefresh = true

                        // If a refresh was already initiated wait for it to finish
                        // Once it finished the latest tokens should be present
                        if (this.isRefreshing) {
                            await waitUntil(() => !this.isRefreshing)
                            const newToken = this.accessToken
                            if (!newToken) {
                                throw new axios.Cancel('Impossible to refresh the token')
                            }
                            const newTokenDecoded = jwtDecode<JwtPayload>(newToken)
                            if (
                                newToken &&
                                config.headers &&
                                newTokenDecoded.exp &&
                                DateTime.fromSeconds(newTokenDecoded.exp) > DateTime.now()
                            ) {
                                config.headers['Authorization'] = `Bearer ${newToken}`
                                return config
                            }
                        }

                        const newToken = await this.refreshAccessToken()
                        if (newToken && config.headers) {
                            config.headers['Authorization'] = `Bearer ${newToken.accessToken}`
                        }
                    }
                    return config
                })

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            } catch {
                this.setAccessToken(null)
                throw new axios.Cancel('Impossible to refresh the token')
            }
        } else {
            delete axios.defaults.headers.common['Authorization']
        }
    }

    public isAuthenticated(): boolean {
        if (this.refreshToken === null) {
            return false
        }

        try {
            const decoded = jwtDecode<JwtPayload>(this.refreshToken)
            if (!decoded.exp) {
                return false
            }

            const expirationDate = DateTime.fromSeconds(decoded.exp)
            if (expirationDate <= DateTime.now()) {
                return false
            }
        } catch {
            this.refreshToken = null
            this.isRefreshing = false
            return false
        }

        return true
    }

    public async login(credentials: DefaultCredentials): Promise<boolean> {
        try {
            const body = new FormData()
            body.append('username', credentials.username)
            body.append('password', credentials.password)

            const result = await axios.post(this.routes.login, body)

            const accessToken = result.data['access_token']
            const refreshToken = result.data['refresh_token']
            if (!accessToken || !refreshToken) {
                return false
            }

            this.setAccessToken(accessToken)
            this.refreshToken = refreshToken
            return true
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async loginExternal(params: { [key: string]: string }): Promise<boolean> {
        try {
            const loginExternal = this.routes.loginExternal
            const result = await axios.post(loginExternal, null, { params: params })

            const accessToken = result.data['access_token']
            const refreshToken = result.data['refresh_token']
            if (!accessToken || !refreshToken) {
                return false
            }

            this.setAccessToken(accessToken)
            this.refreshToken = refreshToken
            return true
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    protected async refreshAccessToken(): Promise<{ accessToken: string; refreshToken: string } | null> {
        if (!this.refreshToken) {
            this.accessToken = null
            return null
        }

        try {
            this.isRefreshing = true
            const body = { refresh_token: this.refreshToken }
            const result = await axios.post(this.routes.refreshAccessToken, body, {
                withCredentials: false
            })
            const accessToken = result.data.access_token
            const refreshToken = result.data.refresh_token

            this.setAccessToken(accessToken)
            this.refreshToken = refreshToken

            return {
                accessToken: accessToken,
                refreshToken: refreshToken
            }
        } catch (e) {
            this.setAccessToken(null)
            this.refreshToken = null

            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        } finally {
            this.isRefreshing = false
        }
    }

    public async logout(): Promise<void> {
        this.setAccessToken(null)
        this.refreshToken = null
        this.isRefreshing = false
    }

    public getExternalLoginRoute(redirectUrl: string): string {
        const querySanitized = encodeURIComponent(redirectUrl)
        const redirectExternalLogin = this.routes.redirectExternalLogin
        const fullPath = `${redirectExternalLogin}?redirect_url=${querySanitized}/`

        return fullPath
    }

    public setOrganization(organizationSlug: string | null): void {
        if (organizationSlug) {
            axios.defaults.headers.common[ORGANIZATION_HEADER_KEY] = organizationSlug
        } else {
            delete axios.defaults.headers.common[ORGANIZATION_HEADER_KEY]
        }
    }
}
