import { flatten } from 'lodash'
import { Expression } from 'mapbox-gl'

export interface LegendRepresentationItem {
    color: string
    label: string
}

export abstract class LegendRepresentation {
    protected _id: string
    protected _label: string
    protected _noEngineItem: LegendRepresentationItem
    protected _legendItems: LegendRepresentationItem[]

    constructor(id: string, label: string, noEngineItem: LegendRepresentationItem, items: LegendRepresentationItem[]) {
        this._id = id
        this._label = label
        this._noEngineItem = noEngineItem
        this._legendItems = items
    }

    public get id(): string {
        return this._id
    }

    public get label(): string {
        return this._label
    }

    public get noEngineItem(): LegendRepresentationItem {
        return this._noEngineItem
    }

    public get legendItems(): LegendRepresentationItem[] {
        return this._legendItems
    }

    public getLayerExpressionNoEngine(defaultColor: string): Expression {
        return [
            'case',
            ['==', ['feature-state', this._id], 'NO_ENGINE'],
            this._noEngineItem.color,
            this.getLayerExpression(defaultColor)
        ]
    }

    public abstract getLayerExpression(defaultColor: string): Expression
}

export interface ValuesLegendRepresentationItem extends LegendRepresentationItem {
    value: number | string
}

export class ValuesLegendRepresentation extends LegendRepresentation {
    private _items: ValuesLegendRepresentationItem[]

    constructor(
        id: string,
        label: string,
        noEngineItem: LegendRepresentationItem,
        items: ValuesLegendRepresentationItem[]
    ) {
        super(id, label, noEngineItem, items)
        this._items = items
    }

    public getLayerExpression(defaultColor: string): Expression {
        return [
            'match',
            ['feature-state', this._id],
            ...flatten(this._items.map((item) => [item.value, item.color])),
            defaultColor
        ]
    }
}

export interface RangeLegendRepresentationItem extends LegendRepresentationItem {
    value: number
}

export interface FirstRangeLegendRepresentationItem extends LegendRepresentationItem {
    hideInLegend?: boolean
}

export class RangeLegendRepresentation extends LegendRepresentation {
    private _firstValue?: FirstRangeLegendRepresentationItem
    private _items: RangeLegendRepresentationItem[]

    constructor(
        id: string,
        label: string,
        noEngineItem: LegendRepresentationItem,
        items: RangeLegendRepresentationItem[],
        firstValue?: FirstRangeLegendRepresentationItem
    ) {
        super(id, label, noEngineItem, firstValue && !firstValue.hideInLegend ? [firstValue, ...items] : items)
        this._firstValue = firstValue
        this._items = items
    }

    public getLayerExpression(defaultColor: string): Expression {
        return [
            'step',
            ['number', ['feature-state', this._id], -1],
            this._firstValue ? this._firstValue.color : defaultColor,
            ...flatten(this._items.map((item) => [item.value, item.color]))
        ]
    }
}

export class BooleanLegendRepresentation extends LegendRepresentation {
    private _noDataValue: LegendRepresentationItem
    private _trueValue: LegendRepresentationItem
    private _falseValue: LegendRepresentationItem

    constructor(
        id: string,
        label: string,
        noEngineItem: LegendRepresentationItem,
        noDataValue: LegendRepresentationItem,
        trueValue: LegendRepresentationItem,
        falseValue: LegendRepresentationItem
    ) {
        super(id, label, noEngineItem, [noDataValue, trueValue, falseValue])
        this._noDataValue = noDataValue
        this._trueValue = trueValue
        this._falseValue = falseValue
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getLayerExpression(defaultColor: string): Expression {
        return [
            'case',
            ['==', ['feature-state', this._id], null],
            this._noDataValue.color,
            ['boolean', ['feature-state', this._id], false],
            this._trueValue.color,
            this._falseValue.color
        ]
    }
}
