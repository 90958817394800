import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { ITranslationAPI, TranslationAPI } from '@/api/translation'
import { Language } from '@/domains/common/models'

import { BaseService } from '../BaseService'
import { ITranslationService } from './ITranslationService'
import { LanguageFactory } from './LanguageFactory'
import { getEnv } from "@/utils/env"

Vue.use(VueI18n)

type LanguageTranslation = { [key: string]: string }

export class TranslationService extends BaseService implements ITranslationService {
    private readonly api: ITranslationAPI = new TranslationAPI()
    protected readonly i18n: VueI18n = new VueI18n({
        fallbackLocale: getEnv("VUE_APP_DEFAULT_LANGUAGE")
    })
    protected languages: Map<string, LanguageTranslation> = new Map()

    public getVuePlugin(): VueI18n {
        return this.i18n
    }

    public async getLanguages(): Promise<Language[]> {
        const dto = await this.api.fetchLanguages()
        if (!dto) {
            throw new Error('languages.errors.not_found')
        }

        return dto.map(LanguageFactory.fromDTO)
    }

    public async useLanguage(code: string): Promise<void> {
        if (this.i18n.locale === code) {
            return Promise.resolve()
        }

        if (this.languages.has(code)) {
            this.configure(code)
            return Promise.resolve()
        }

        const language = await this.api.fetchLanguage(code)

        this.languages.set(code, language)
        this.configure(code)
    }

    protected configure(code: string): void {
        const language = this.languages.get(code)
        if (!language) {
            return
        }

        this.i18n.setLocaleMessage(code, language)
        this.i18n.locale = code
        document.querySelector('html')?.setAttribute('lang', code)
    }

    public translate(key: string): string {
        return this.i18n.t(key).toString()
    }
}
