import { FullAuthAPI, IAuthAPI } from '@/api/auth'
import { DefaultCredentials } from '@/api/auth/model/DefaultCredentials'

import { BaseService } from '../BaseService'
import { IUserService } from '../user'
import { IAuthService } from './IAuthService'
import { getEnv } from "@/utils/env"

export class AuthService extends BaseService implements IAuthService {
    private readonly api: IAuthAPI = new FullAuthAPI()

    private initialized = false

    public async login(username: string, password: string): Promise<boolean> {
        this.serviceLocator.get<IUserService>('user').clear()
        return await this.api.login(new DefaultCredentials(username, password))
    }

    public getExternalLoginText(): string {
        return getEnv("VUE_APP_EXTERNAL_LOGIN_TEXT")
    }

    public hasExternalLogin(): boolean {
        return !!getEnv("VUE_APP_EXTERNAL_LOGIN_TEXT")
    }

    public async loginExternal(query_params: { [key: string]: string | (string | null)[] }): Promise<boolean> {
        this.serviceLocator.get<IUserService>('user').clear()
        return await this.api.loginExternal(query_params)
    }

    public redirectToExternalLogin(redirectPath: string): void {
        const baseUrl = `${window.location.protocol}//${window.location.host}`
        window.location.href = this.api.getExternalLoginRoute(`${baseUrl}${redirectPath}`)
    }

    public async isAuthenticated(): Promise<boolean> {
        if (!this.initialized) {
            await this.initialize()
        }
        return this.api.isAuthenticated()
    }

    public logout(): void {
        this.api.logout()
        this.serviceLocator.get<IUserService>('user').clear()
    }

    public setOrganization(organizationSlug: string | null): void {
        this.api.setOrganization(organizationSlug)
    }

    private async initialize() {
        try {
            await this.api.initialize()
        } catch (exception) {
            console.error(exception)
        }

        this.initialized = true
    }
}
